<template>
    <b-modal id="modal-event-preview" size="xl" hide-header content-class="px-1 pt-2" scrollable>
        <template #modal-footer>
            <b-button variant="primary" class="mx-auto" @click="onClose">Close</b-button>
        </template>
        <b-container fluid class="p-3 bg-grey">
            <b-row no-gutters>
                <h4>Basic Information</h4>
            </b-row>
            <b-row class="my-3">
                <b-col>
                    <div class="mb-1">Event Title</div>
                    <div><b>{{ event.title }}</b></div>
                </b-col>
                <b-col>
                    <div class="mb-1">Location</div>
                    <div><b>{{ event.location }}</b></div>
                </b-col>
                <b-col>
                    <div class="mb-1">Project</div>
                    <div><b>{{ event.project }}</b></div>
                </b-col>
            </b-row>
            <b-row class="my-3">
                <b-col>
                    <div class="mb-1">Source Channel</div>
                    <div><b>{{ event.sourceChannel }}</b></div>
                </b-col>
                <b-col>
                  <div class="mb-1">Channel Country</div>
                  <div><b>{{ event.channelCountry }}</b></div>
                </b-col>
                <b-col>
                    <div class="mb-1">Exclusive To</div>
                    <div><b>{{ event.exclusiveTo }}</b></div>
                </b-col>
            </b-row>
            <b-row class="my-3">
                <b-col>
                    <div class="mb-1">Event Date</div>
                    <div><b>{{ displayEventDate }}</b></div>
                </b-col>
                <b-col>
                    <div class="mb-1">Start Time</div>
                    <div><b>{{ displayStartTime }}</b></div>
                </b-col>
                <b-col>
                    <div class="mb-1">End Time</div>
                    <div><b>{{ displayEndTime }}</b></div>
                </b-col>
            </b-row>
            <b-row class="my-3">
                <b-col cols="4">
                    <div class="mb-1">Event Type</div>
                    <div><b>{{ event.type }}</b></div>
                </b-col>
                <b-col cols="4">
                    <div class="mb-1">Category</div>
                    <div><b>{{ event.category }}</b></div>
                </b-col>
                <b-col>
                  <div class="mb-1">Image Upload</div>
                  <div>
                    <img width="100" height="100" :src="event.thumbnail" />
                  </div>
                </b-col>
            </b-row>
            <b-row v-if="hasGuests" class="my-3">
                <b-col >
                  <div class="mb-1">Guests</div>
                  <div><b>{{ displayGuests }}</b></div>
                </b-col>
            </b-row>
        </b-container>

        <b-container class="mt-2 p-3 bg-grey">
            <b-row no-gutters>
                <h4>Settings</h4>
            </b-row>
            <b-row class="my-3">
                <b-col>
                    <div class="mb-1">Budget</div>
                    <div v-if="event.budget"><b>{{ displayBudget }}</b></div>
                </b-col>
                <b-col>
                    <div class="mb-1">Maximum Pax</div>
                    <div><b>{{ event.maxPax }}</b></div>
                </b-col>
                <b-col>
                    <div class="mb-1">Open for Registration</div>
                    <div><b>{{ openForRegistration }}</b></div>
                </b-col>
            </b-row>
            <b-row class="my-3">
                <b-col>
                    <div class="mb-1">Conduct Survey after Event</div>
                    <div><b>{{ conductSurvey }}</b></div>
                </b-col>
                <b-col>
                    <div class="mb-1">Event Happen at Sales Gallery</div>
                    <div><b>{{ atSalesGallery }}</b></div>
                </b-col>
                <b-col>
                    <div class="mb-1">Display at KORA App</div>
                    <div><b>{{ displayAtApp }}</b></div>
                </b-col>
            </b-row>
            <b-row class="my-3">
                <b-col>
                    <div class="mb-1">Display at Home Page</div>
                    <div><b>{{ displayAtHomepage }}</b></div>
                </b-col>
                <b-col cols="8">
                    <div class="mb-1">EDM</div>
                    <div>
                        <span class="edm-image" v-for="edm in event.edm" :key="edm.id">
                            <img width="100" height="100" :src="edm.edm_path" />
                        </span>
                    </div>
                </b-col>
            </b-row>
            <b-row class="my-3">
                <b-col cols="8">
                    <div class="mb-1">Short Description</div>
                    <div><b>{{ event.shortDesc }}</b></div>
                </b-col>
            </b-row>
            <b-row class="my-3">
                <b-col cols="12">
                    <div class="mb-1">Long Description</div>
                    <div v-html="event.longDesc"></div>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol, BButton } from 'bootstrap-vue'
import moment from 'moment'

export default {
    components: {
        BModal,
        BContainer,
        BRow,
        BCol,
        BButton
    },
    props: {
        event: null
    },
    data() {
        return {

        }
    },
    computed: {
        displayBudget() {
            return `RM ${this.event.budget.toLocaleString()}`;
        },
        displayEventDate() {
            return this.event.startDateTime ? moment(this.event.startDateTime).format("DD MMM yyyy") : '-';
        },
        displayStartTime() {
            return this.event.startDateTime ? moment(this.event.startDateTime).format("h:mm a") : '-';
        },
        displayEndTime() {
            return this.event.endDateTime ? moment(this.event.endDateTime).format("h:mm a") : '-';
        },
        openForRegistration() {
            return this.event.isRegistrable ? 'Yes' : 'No'
        },
        conductSurvey() {
            return this.event.conductSurvey ? 'Yes' : 'No'
        },
        atSalesGallery() {
            return this.event.atSalesGallery ? 'Yes' : 'No'
        },
        displayAtApp() {
            return this.event.displayAtApp ? 'Yes' : 'No'
        },
        displayAtHomepage() {
            return this.event.displayAtHomepage ? 'Yes' : 'No'
        },
        displayGuests() {
            return this.event.invitees ? this.event.invitees.map(invitee => invitee.name).join(', ') : '-';
        },
        hasGuests() {
            return this.event.invitees && this.event.invitees.length > 0;
        }
    },
    methods: {
        onClose() {
            this.$bvModal.hide('modal-event-preview')
        }
    }
}
</script>

<style>
.bg-grey {
    background-color: #F7F7F7;
    border: 1px solid #261F1F1F;
}

.edm-image {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
</style>