<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Event Listing">
        <b-row class="mb-2" no-gutters>
          <b-col md="auto">
            <b-dropdown menu-class="dropdown-filter-width" id="filter-form" ref="dropdown"
              :variant="numOfFiltersSelected > 0 ? 'primary' : 'outline-primary'" class="dropdown-icon-wrapper">
              <template #button-content class="align-middle">
                <feather-icon icon="FilterIcon" class="align-middle" />
                <span class="mx-1">Filter</span>
                <b-badge v-if="numOfFiltersSelected > 0" pill="true" class="bg-white text-primary">{{
                    numOfFiltersSelected
                }}
                </b-badge>
              </template>
              <event-filter @filterApplied="onFilterApplied" :eventStatusOptions="eventStatusOptions"
                :registrationStatusOptions="registrationStatusOptions" />
            </b-dropdown>

          </b-col>
          <b-col cols="4" class="mx-2">
            <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
          </b-col>
          <b-col class="text-right">
            <b-button variant="primary" :to="{ name: 'event.create' }">
              <FeatherIcon icon="PlusIcon"></FeatherIcon> Add New Event
            </b-button>
          </b-col>
        </b-row>

        <b-table class="table-height" hover responsive sticky-header="true" no-border-collapse :per-page="pageLength"
          :current-page="currentPage" :items="rows" :fields="columns" :filter="searchTerm"
          :filter-included-fields="filterColumns" @filtered="onSearched">
          <template #cell(title)="data">
            <span>
              <b-link :to="{ name: 'event.attendance.index', params: { eventId: data.item.id } }"
                :class="{ 'text-mute': data.item.isDraft }">
                {{ data.value }}
              </b-link>
              <b-badge class="ml-1" v-if="data.item.isDraft" variant="light-warning">Draft</b-badge>
            </span>
          </template>

          <template #cell(startDateTime)="data">
            <span>{{ toFormattedDateTime(data.value) }}</span>
          </template>

          <template #cell(registrationStatus)="data">
            <b-badge :variant="registrationStatusVariant(data.value)">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(eventStatus)="data">
            <b-badge :variant="eventStatusVariant(data.value)">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <span class="d-flex">
              <b-button variant="flat" class="btn-icon rounded-circle" v-b-modal.modal-event-preview
                @click="onPreviewClick(data.item)">
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button variant="flat" class="btn-icon rounded-circle my-auto"
                :to="{ name: 'event.attendance.index', params: { eventId: data.item.id } }">
                <feather-icon icon="UsersIcon" />
              </b-button>
              <b-dropdown variant="link" toggle-class="text-decoration-none p-1" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body" />
                </template>
                <b-dropdown-item @click="pushNotificationClick(data.item)">
                  <feather-icon icon="BellIcon" class="mr-50" />
                  <span>Send Push Notification</span>
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.rsvp-link @click="rsvpLinkClick(data.item)">
                  <feather-icon icon="LinkIcon" class="mr-50" />
                  <span>RSVP Link</span>
                </b-dropdown-item>
                <b-dropdown-item @click="goToEditEvent(data.item)">
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteEventClick(data.item)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
        </b-table>

        <div class="mt-2 d-flex justify-content-between flex-wrap">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select v-model="pageLength" size="sm" :options="entriesPerPageOptions" class="w-50" />
          </b-form-group>

          <b-pagination v-model="currentPage" :total-rows="tableItemCount" :per-page="pageLength" size="sm"
            class="my-0" />
        </div>

        <event-preview :event="selectedPreview" />
        <event-rsvp-link :link="selectedWebFormLink" :title="selectedEventTitle" />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar, BBadge, BCard, BCol, BLink, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BFormCheckbox, BFormCheckboxGroup, BRow, BTable,
} from 'bootstrap-vue'
import store from '@/store/index'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import EventPreview from './EventPreview.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import EventFilter from './EventFilter.vue'
import moment from 'moment'
import EventRsvpLink from './EventRsvpLink.vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BLink,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BTable,
    store,
    FeatherIcon,
    BFormCheckboxGroup,
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
    EventPreview,
    ToastificationContent,
    EventFilter,
    EventRsvpLink,
  },
  data() {
    return {
      pageLength: 10,
      entriesPerPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      dir: false,
      columns: [
        {
          label: 'Title',
          key: 'title',
          sortable: true,
        },
        {
          label: 'Type',
          key: 'type',
          sortable: true,
        },
        {
          label: 'Start Date',
          key: 'startDateTime',
          sortable: true,
        },
        {
          label: 'Exclusive To',
          key: 'exclusiveTo',
          sortable: true,
        },
        {
          label: 'Total Attendance',
          key: 'totalAttendance',
          sortable: true,
        },
        {
          label: 'Registration Status',
          key: 'registrationStatus',
          sortable: true,
        },
        {
          label: 'Event Status',
          key: 'eventStatus',
          sortable: true,
        },
        {
          label: 'Actions',
          key: 'actions',
        },
      ],
      rows: [],
      oriData: [],
      filterColumns: [
        'title', 'type', 'startDateTime', 'exclusiveTo', 'registrationStatus', 'eventStatus'
      ],
      searchTerm: '',
      searchResultCount: 0,
      numOfFiltersSelected: 0,
      selectedPreview: null,
      selectedWebFormLink: '',
      selectedEventTitle: '',
      eventStatusOptions: [
        {
          text: 'Current',
          value: 'Current',
          statusColor: 'success'
        },
        {
          text: 'Upcoming',
          value: 'Upcoming',
          statusColor: 'warning'
        },
        {
          text: 'Past',
          value: 'Past',
          statusColor: 'secondary'
        },
        {
          text: 'NA',
          value: 'NA',
          statusColor: 'light'
        },
      ],
      registrationStatusOptions: [
        {
          text: 'Open',
          value: 'Open',
          statusColor: 'success'
        },
        {
          text: 'Upcoming',
          value: 'Upcoming',
          statusColor: 'warning'
        },
        {
          text: 'Fully Booked',
          value: 'Fully Booked',
          statusColor: 'danger'
        },
        {
          text: 'Closed',
          value: 'Closed',
          statusColor: 'secondary'
        },
        {
          text: 'NA',
          value: 'NA',
          statusColor: 'light'
        },
      ],
    }
  },
  computed: {
    registrationStatusVariant() {
      return status => this.registrationStatusOptions.find(option => option.value === status)?.statusColor;
    },
    eventStatusVariant() {
      return status => this.eventStatusOptions.find(option => option.value === status)?.statusColor;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    tableItemCount() {
      if (this.searchTerm) {
        return this.searchResultCount;
      } else {
        return this.rows.length;
      }
    }
  },
  mounted() {
    this.getEventList();
  },
  methods: {
    getEventList() {
      this.$http.get('api/event')
        .then(res => {
          var mData = res.data.data;
          this.oriData = mData.map(this.mapToRow);
          this.rows = this.oriData;
        })
        .catch(error => {
          this.showErrorToast(error);
        });
    },
    onFilterApplied(args) {
      this.$refs.dropdown.hide(true)
      const registrationStatuses = args.regStatus;
      const eventStatuses = args.eventStatus;
      this.numOfFiltersSelected = registrationStatuses.length + eventStatuses.length;

      if (registrationStatuses.length || eventStatuses.length) {
        this.rows = this.oriData.filter(value => {
          const matchRegistrationStatus = registrationStatuses.length
            ? registrationStatuses.includes(value.registrationStatus)
            : true;
          const matchEventStatus = eventStatuses.length
            ? eventStatuses.includes(value.eventStatus)
            : true;
          return matchRegistrationStatus && matchEventStatus;
        })
      } else {
        this.rows = this.oriData
      }
    },
    onPreviewClick(data) {
      this.selectedPreview = data;
      this.$http.get(`api/event/${data.id}`)
        .then(res => {
          var mData = res.data.data;
          this.selectedPreview = this.mapToRow(mData);
        })
        .catch(error => {
          this.showErrorToast(error);
        });
    },
    mapToRow(data) {
      return {
        id: data.id,
        title: data.name,
        type: data.type,
        startDateTime: data.start_date,
        exclusiveTo: data.tiers.map(this.mapTierToTitle).join(", "),
        totalAttendance: `${data.attendees_count}/${data.attendances_count}`,
        registrationStatus: data.registration_status,
        eventStatus: data.event_status,
        isDraft: data.is_draft,
        location: data.location,
        project: data.project_name,
        sourceChannel: data.source_channel_name,
        channelCountry: data.channel_country,
        category: data.category,
        budget: data.budget,
        maxPax: data.no_of_pax,
        conductSurvey: data.has_survey,
        atSalesGallery: data.at_sales_gallery,
        displayAtApp: data.is_public,
        displayAtHomepage: data.is_featured,
        edm: data.edms,
        shortDesc: data.short_description,
        longDesc: data.description,
        thumbnail: data.thumbnail,
        isRegistrable: data.is_registrable,
        invitees: data.invitees,
        webFormUrl: data.web_form_url,
      };
    },
    mapTierToTitle(tier) {
      return tier.title;
    },
    pushNotificationClick(event) {
      this.$swal({
        text: "Are you sure you want to send push notification to the KORA app?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-danger',
        },
        buttonsStyling: false,
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.$http.post(`api/push-notification/event/${event.id}`)
            .then(result => {
              if (result.status === 200) {
                this.showPushSuccess();
              }
            })
            .catch(error => {
              this.showErrorToast(error);
            });
        }
      });
    },
    deleteEventClick(event) {
      this.$swal({
        text: "Are you sure you want to delete the event?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-danger',
        },
        buttonsStyling: false,
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`api/event/${event.id}`)
            .then(res => {
              if (res.status === 200) {
                this.getEventList();
                this.showDeleteSuccess();
              }
            })
            .catch(error => {
              this.showErrorToast(error);
            });
        }
      });
    },
    toFormattedDateTime(date) {
      return date ? moment(date, 'yyyy-MM-DD HH:mm').format('DD/MM/yyyy, h:mm a') : '-';
    },
    showErrorToast(errorTxt) {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'AlertCircleIcon',
          text: errorTxt,
          variant: 'danger',
        },
      });
    },
    showDeleteSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          icon: 'CheckIcon',
          text: 'Event has been successfully deleted',
          variant: 'success',
        },
      });
    },
    showPushSuccess() {
      this.$swal({
        icon: 'success',
        text: 'Push notifications have successfully been sent',
        showConfirmButton: false,
        timer: 3000
      });
    },
    goToEditEvent(event) {
      this.$router.push({ name: "event.edit", params: { eventId: `${event.id}` } });
    },
    onSearched(items, numOfResults) {
      this.searchResultCount = numOfResults;
      this.currentPage = 1;
    },
    rsvpLinkClick(event) {
      this.selectedWebFormLink = event.webFormUrl;
      this.selectedEventTitle = event.title;
    },
  }
}
</script>

<style lang="scss" >
.dropdown-filter-width {
  width: 400px
}

th {
  background-color: #F6F8FC !important;
}

.table-height {
  height: 500px;
  max-height: 500px;
}
</style>
