<template>
    <b-dropdown-form class="py-1">
        <app-collapse>
            <app-collapse-item title="Registration Status" :isVisible="true">
                <b-row>
                    <b-col class="mt-1" cols="6" v-for="option in filteredRegistrationStatusOptions" :key="option.value">
                        <b-form-checkbox v-model="selectedRegistrationStatus" :value="option.value">{{ option.text }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </app-collapse-item>

            <app-collapse-item title="Event Status" :isVisible="true">
                <b-row>
                    <b-col class="mt-1" cols="6" v-for="option in filteredEventStatusOptions" :key="option.value">
                        <b-form-checkbox v-model="selectedEventStatus" :value="option.value">{{ option.text }}</b-form-checkbox>
                    </b-col>
                </b-row>
            </app-collapse-item>
        </app-collapse>

        <b-button class="m-1" variant="outline-primary" @click="onApplyClick">
            Apply
        </b-button>
    </b-dropdown-form>
</template>

<script>
import {
    BDropdownForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox,
    BButton, BRow, BCol
} from 'bootstrap-vue'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'

export default {
    components: {
        BDropdownForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        AppCollapse,
        AppCollapseItem,
        BButton,
        BRow,
        BCol
    },
    data() {
        return {
            selectedRegistrationStatus: [],
            selectedEventStatus: [],
        }
    },
    props: {
        eventStatusOptions: {
            type: Array
        },
        registrationStatusOptions: {
            type: Array
        }
    },
    emits: ['filterApplied'],
    methods: {
        onApplyClick() {
            this.$emit('filterApplied',
                {
                    regStatus: this.selectedRegistrationStatus,
                    eventStatus: this.selectedEventStatus
                });
        }
    },
    computed: {
        filteredEventStatusOptions() {
            return this.eventStatusOptions.filter(option => option.value != 'NA');
        },
        filteredRegistrationStatusOptions() {
            return this.registrationStatusOptions.filter(option => option.value != 'NA');
        }
    }
}
</script>

<style>
</style>