<template>
    <b-modal hide-header id="rsvp-link" ok-only ok-title="Close" centered size="lg">
        <div class="m-2">
            <div class="mx-auto text-center">
                <h3>
                    <b>{{ title }}</b>
                </h3>
            </div>
            <hr class="my-2" />
            <span>
                Share Link
            </span>
            <div class="mt-1">
                <b-input-group>
                    <b-form-input disabled v-model="link" />
                    <b-input-group-append>
                        <b-button id="copy-rsvp-link" variant="outline-primary" @click="copyRsvpLink">
                            <feather-icon icon="CopyIcon" />
                        </b-button>
                        <b-tooltip ref="copyTooltip" target="copy-rsvp-link" triggers="manual">
                            Copied
                        </b-tooltip>
                        <b-button variant="outline-primary" :href="link" target="_blank">
                            <feather-icon icon="ExternalLinkIcon" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
        <template #modal-footer="{ ok }">
            <b-button variant="primary" class="mx-auto" @click="ok()">Close</b-button>
        </template>
    </b-modal>
</template>

<script>
import { BModal, BInputGroup, BFormInput, BButton, BTooltip } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
    components: {
        BModal,
        BInputGroup,
        BFormInput,
        BButton,
        FeatherIcon,
        BTooltip
    },
    methods: {
        copyRsvpLink() {
            navigator.clipboard.writeText(this.link)
                .then(() => {
                    this.$refs.copyTooltip.$emit('open');
                });
        }
    },
    props: {
        link: '',
        title: '',
    },
}
</script>

<style>

</style>